import { useEffect, useState } from "react"
import { Button, Grid, IconButton, Popover, Typography, keyframes } from "@mui/material"
import Breadcrumb, { localeURLS } from "components/breadcrumbs"
import { useLocation, useNavigate } from 'react-router-dom';
import TableLending from "components/tableLending";
import { SalesTableProps } from "types/proposals";
import { colors } from "theme";
import useAuth from "context/auth";
import StartLend from "components/startLend";
import RemoteServices from "service";
import Toast from "components/@ui/toast";

import { Tooltip } from "@mui/material"
import TooltipUI from "components/@ui/Tooltip";
import { FiCheck } from "react-icons/fi";
import ButtonUI from "components/@ui/button";
import CopyLink from "./copyLink";
import useMediasQuerys from "hooks/mediasQuery";
import { BsQuestionCircle } from "react-icons/bs";
import { MainteningModal } from "components/mainteningModal";

const SelectedTable = () => {

    const { myTax } = useAuth()

    const { matchesMaxMobile } = useMediasQuerys()


    const { state, pathname } = useLocation();

    const navigate = useNavigate();

    const [showInfoClint, setShowInfoClint] = useState<boolean>(false);

    const [saleTable, setSaleTable] = useState<SalesTableProps | null>(null)

    const [newLink, setNewLink] = useState('')

    const [openLinkCopy, setOpenLinkCopy] = useState(false)

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [anchorEl2, setAnchorEl2] = useState<HTMLButtonElement | null>(null);

    const open2 = Boolean(anchorEl2);
    const id2 = open2 ? 'simple-popover2' : undefined;

    const [modalMaintening, setModalMaintening] = useState(false)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorEl2(null)
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        if (!state) {
            Toast({
                type: 'warning'
            }, 'Selecione uma tabela para continuar.')
            setTimeout(() => {
                navigate(localeURLS.emprestar)
            }, 2000)
        }
        if (state?.saleTable) {
            setSaleTable(state.saleTable)
        } else {
            setSaleTable(state)
        }
    }, [pathname, state])

    const pulseAnimation = keyframes`
        0% {
        transform: scale(1);
        }
        50% {
        transform: scale(1.${matchesMaxMobile ? '1' : '150'});
        }
        100% {
        transform: scale(1);
        }
    `


    async function getLink(table: string, copy: boolean) {
        RemoteServices.proposals.GenerateLink({
            saleTable: String(table)
        }).then(({ response }) => {
            if (response?.id) {
                setNewLink(`https://emprestimo.ayude.com.br/${response.id}`)
                if (copy) {
                    navigator.clipboard.writeText(`https://emprestimo.ayude.com.br/${response.id}`)
                    Toast({
                        type: 'success'
                    }, 'Link copiado!')
                }
            }
        }).catch(() => {
            Toast({
                type: 'error'
            }, 'Falha ao gerar link')
        }).finally(() => { })
    }

    return (
        <Grid height={"100%"} p={2}>
            <Breadcrumb />
            <Grid
                height={"calc(100% - 64px)"}
                position="relative"
                container
                alignItems={"center"}
                sx={{ overflowX: 'hidden', overflowY: 'auto' }}
                flexDirection={"column"}
                mt={2}
                flexWrap={"nowrap"}
                gap={4}
                p={2}>
                <Typography
                    width="100%"
                    variant="h4"
                    fontWeight="bold"
                    sx={{ my: 2 }}
                    textTransform="uppercase">
                    Tabela selecionada
                </Typography>
                <TableLending
                    notableNameComplete
                    key={saleTable?.id}
                    sx={{ mt: 5 }}
                    color={
                        Number(saleTable?.sale_table.replace('on-', '')) <= 3 ?
                            colors.greenTable : (Number(saleTable?.sale_table?.replace('on-', '')) <= 6
                                ? colors.blueTable : (Number(saleTable?.sale_table?.replace('on-', '')) <= 9
                                    ? colors.yellowTable : (Number(saleTable?.sale_table?.replace('on-', '')) <= 12
                                        ? colors.orangeTable : colors.redTable)))
                    }
                    item={saleTable} />
                <Grid
                    container
                    gap={2}
                    flexDirection={'column'}
                    justifyContent="center">
                    <TooltipUI
                        title="Clique para digitar os dados cadastrais do cliente e iniciar o empréstimo.">
                        <Grid container justifyContent={"space-around"}>
                            <Button
                                onClick={() => {
                                    setShowInfoClint(true)
                                    // setModalMaintening(true)
                                }}
                                disableElevation
                                variant="contained"
                                sx={{
                                    maxWidth: 260,
                                    width: '100%',
                                    p: 2,
                                    animation: `${pulseAnimation} 2s ease infinite`
                                }}>
                                Iniciar empréstimo
                            </Button>
                            {
                                matchesMaxMobile && <>
                                    <IconButton onClick={handleClick}>
                                        <BsQuestionCircle />
                                    </IconButton>
                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <Typography sx={{ p: 2 }}>Clique para digitar os dados cadastrais do cliente e iniciar o empréstimo.</Typography>
                                    </Popover>
                                </>
                            }

                        </Grid>
                    </TooltipUI>
                    <Typography textAlign={'center'}>ou</Typography>
                    <TooltipUI
                        title="Copie o Link ou baixe o QR Code de autoempréstimo e divulgue em seu site, WhatsApp e outras redes sociais.">
                        <Grid container justifyContent={"space-around"}>
                            <Button
                                onClick={() => setOpenLinkCopy(true)}
                                variant="outlined"
                                color={"primary"}
                                sx={{ maxWidth: 260, width: '100%', p: 2, fontSize: 13 }}>
                                Copiar o Link ou baixar QR Code
                            </Button>
                            {
                                matchesMaxMobile && <>

                                    <IconButton onClick={handleClick2}>
                                        <BsQuestionCircle />
                                    </IconButton>
                                    <Popover
                                        id={id2}
                                        open={open2}
                                        anchorEl={anchorEl2}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <Typography sx={{ p: 2 }}>Copie o Link ou baixe o QR Code de autoempréstimo e divulgue em seu site, WhatsApp e outras redes sociais.</Typography>
                                    </Popover>
                                </>
                            }
                        </Grid>
                    </TooltipUI>
                </Grid>
                {
                    !matchesMaxMobile &&
                    <Button
                        sx={{
                            position: 'absolute',
                            left: 0,
                            bottom: 0,
                            p: 1,
                            width: 120,
                            textDecoration: 'underline',
                        }}
                        color="inherit"
                        onClick={() => navigate(`${localeURLS.emprestar}?noreload`)}
                        variant="text">
                        Voltar
                    </Button>
                }
            </Grid>
            <StartLend
                saleTable={saleTable}
                typeSimulator="perTable"
                open={showInfoClint}
                setSaleTable={setSaleTable}
                handleClose={() => setShowInfoClint(false)} />
            <CopyLink
                getLink={getLink}
                open={openLinkCopy}
                handleClose={() => setOpenLinkCopy(false)}
                link={newLink}
                newLink={newLink}
                saleTable={saleTable}
            />
            <MainteningModal
                handleClose={() => {setModalMaintening(false)}}
                open={modalMaintening}
            />
        </Grid>
    )
}

export default SelectedTable;