import * as yup from 'yup';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import googleLibphone from 'google-libphonenumber';
import moment from 'moment';
import { formatDateOnly } from './format';

moment.locale('pt-br');

const issueMinDate = new Date(new Date().getTime() - (86400000 * 3650))
const minDataBirthday = new Date(new Date().getTime() - (86400000 * ((18 * 365) + 4)))

const phoneUtil = googleLibphone.PhoneNumberUtil.getInstance();

const validateCellphone = (number: string) => {
  const validNumber = validateNumber(number);

  if (!validNumber) {
    return false;
  }

  const typeNumber = phoneUtil.getNumberType(validNumber);

  if (typeNumber !== 1) {
    return false;
  }

  return true;
};

const validateNumber = (number: string) => {
  try {
    const parserNumber = phoneUtil.parseAndKeepRawInput(number, 'BR');
    const validationPhone = phoneUtil.isValidNumberForRegion(
      parserNumber,
      'BR',
    );

    if (!validationPhone) {
      return false;
    }

    return parserNumber;
  } catch (err) {
    return false;
  }
};

const today = moment().format(moment.HTML5_FMT.DATE);


const registerStep = yup.object({
  name: yup
    .string()
    .required('Digite o nome completo do cliente.'),
  gender: yup
    .mixed().oneOf(['F', 'M'], 'Selecione o gênero do cliente.')
    .required('Selecione o gênero do cliente.'),
  email: yup
    .string()
    .email('Informe um email válido. Ex.: exemplo@exemplo.com;')
    .required('Informe o e-mail do cliente.'),

  cellphone: yup
    .string()
    .test(
      'validateBRCellphone',
      'Informe o Whatsapp do cliente.',
      // @ts-ignore
      validateCellphone,
    )
    .required('Informe o Whatsapp do cliente.'),



  CEP: yup
    .string()
    .matches(/^[\d]{5}-[\d]{3}$/, 'Digite o atual CEP do cliente.')
    .required('Digite o atual CEP do cliente.'),

  state: yup
    .mixed()
    .oneOf(
      [
        'AC',
        'AL',
        'AP',
        'AM',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MT',
        'MS',
        'MG',
        'PA',
        'PB',
        'PR',
        'PE',
        'PI',
        'RJ',
        'RN',
        'RS',
        'RO',
        'RR',
        'SC',
        'SP',
        'SE',
        'TO',
      ],
      'Selecione o atual Estado do cliente.',
    )
    .required('Selecione o atual Estado do cliente.'),

  city: yup.string().trim().required('Selecione a atual cidade do cliente.'),

  district: yup
    .string()
    .trim()
    .min(4, 'Digite o atual bairro do cliente.')
    .required('Digite o atual bairro do cliente.'),
  address: yup
    .string()
    .trim()
    .min(4, 'Digite o atual endereço completo do cliente.')
    .required('Digite o atual endereço completo do cliente.'),

  addressNumber: yup.string().trim().required('Digite o número da sua residência do cliente.'),
  rg: yup
    .string()
    .trim()
    .max(20, 'RG ou CNH deve ter no máximo 20 caracteres.')
    .required('Digite o RG ou CNH corretamente.'),

  issuingAgency: yup.string().trim().required('Informe o órgão expedidor do documento do cliente.'),

  issueDate: yup
    .date()
    .nullable()
    .min(
      issueMinDate,
      `Data de emissão do documento deve ser igual ou superior a ${formatDateOnly(issueMinDate)
      }.`,
    )
    .max(
      today,
      `Data de emissão do documento deve ser igual ou inferior a ${formatDateOnly(
        new Date(today),
      )}.`,
    )
    .required('Informe a data que seu documento foi expedido;'),
})

const schemaSalesCounter = yup.object().shape({
  tradeName: yup.string(),
  responsibleName: yup
    .string()
    .trim()
    .min(
      4,
      'Nome do responsável muito curto. Favor, informar o nome completo do responsável.',
    )
    .required('Informe o nome do responsável pelas vendas no balcão.'),
  CPF: yup.string().test('validateCPF', 'Informe um CPF válido.', (value) => {
    return cpf.isValid(value as string);
  }),
  CNPJ: yup.mixed().test('validateCNPJ', 'Informe um CNPJ válido.', (value) => {
    if (value) {
      return cnpj.isValid(value as string);
    }
    return true;
  }),
  password: yup
    .string()
    .nullable()
    .min(6, 'A senha deve ter no mínimo 6 dígitos.'),
});


const schemaSalesCounterUpdate = yup.object().shape({
  tradeName: yup
  .string()
  .trim()
  .when('CNPJ', {
    is: (CNPJ: string | undefined | null) => {
      return CNPJ !== null
    },
    then: (s: any) => s.required('Informe o nome fantasia'),
    otherwise: (s: any) => s,
  }),
  responsibleName: yup
    .string()
    .trim()
    .min(
      4,
      'Nome do responsável muito curto. Favor, informar o nome completo do responsável.',
    )
    .required('Informe o nome do responsável pelas vendas no balcão.'),
  CPF: yup.string().test('validateCPF', 'Informe um CPF válido.', (value) => {
    return cpf.isValid(value as string);
  }),
  CNPJ: yup.mixed().nullable().test('validateCNPJ', 'Informe um CNPJ válido.', (value) => {
    if (value) {
      return cnpj.isValid(value as string);
    }
    return true;
  }),
  cellphone: yup
    .string()
    .test(
      'validateBRCellphone',
      'Informe um número de Whatsapp válido.',
      // @ts-ignore
      validateCellphone,
    )
    .required('Informe o número de Whatsapp do balcão.'),
  email: yup
    .string()
    .email('Informe um email válido. Ex: exemplo@exemplo.com.')
    .required('Informe o email para login do balcão.'),
  password: yup
    .string()
    .nullable()
    .min(6, 'A senha deve ter no mínimo 6 dígitos.'),
  address: yup
    .string()
    .trim()
    .min(4, 'Logradouro muito curto. Favor, informar o logradouro completo.')
    .required('Informe o logradouro.'),
  addressNumber: yup.string().trim().required('Informe o número.'),
  district: yup
    .string()
    .trim()
    .min(4, 'Bairro muito curto. Favor, informar o nome completo do bairro.')
    .required('Informe o bairro.'),
  CEP: yup
    .string()
    .matches(/^[\d]{5}-[\d]{3}$/, 'Informe um CEP válido.')
    .required('Informe o CEP.'),
  city: yup.string().trim().required('Selecione a cidade.'),
  state: yup.object().shape({
    nome: yup.string(),
    sigla: yup
      .mixed()
      .oneOf(
        [
          'AC',
          'AL',
          'AP',
          'AM',
          'BA',
          'CE',
          'DF',
          'ES',
          'GO',
          'MA',
          'MT',
          'MS',
          'MG',
          'PA',
          'PB',
          'PR',
          'PE',
          'PI',
          'RJ',
          'RN',
          'RS',
          'RO',
          'RR',
          'SC',
          'SP',
          'SE',
          'TO',
        ],
        'Selecione o estado.',
      )
      .required('Selecione o estado.'),
  }),
});



const schemaSalesCounterCreate = yup.object().shape({
  tradeName: yup.string().trim(),
  responsibleName: yup
    .string()
    .trim()
    .min(
      4,
      'Nome do responsável muito curto. Favor, informar o nome completo do responsável.',
    )
    .required('Informe o nome do responsável pelas vendas no balcão.'),
  CPF: yup.string().test('validateCPF', 'Informe um CPF válido.', (value) => {
    return cpf.isValid(value as string);
  }),
  CNPJ: yup.mixed().nullable().test('validateCNPJ', 'Informe um CNPJ válido.', (value) => {
    if (value) {
      return cnpj.isValid(value as string);
    }
    return true;
  }),
  cellphone: yup
    .string()
    .test(
      'validateBRCellphone',
      'Informe um número de Whatsapp válido.',
      // @ts-ignore
      validateCellphone,
    )
    .required('Informe o número de Whatsapp do balcão.'),
  email: yup
    .string()
    .email('Informe um email válido. Ex: exemplo@exemplo.com.')
    .required('Informe o email para login do balcão.'),
  password: yup
    .string()
    .min(6, 'A senha deve ter no mínimo 6 dígitos.')
    .required('Informe a senha de acesso.'),
  address: yup
    .string()
    .trim()
    .min(4, 'Logradouro muito curto. Favor, informar o logradouro completo.')
    .required('Informe o logradouro.'),
  addressNumber: yup.string().trim().required('Informe o número.'),
  district: yup
    .string()
    .trim()
    .min(4, 'Bairro muito curto. Favor, informar o nome completo do bairro.')
    .required('Informe o bairro.'),
  CEP: yup
    .string()
    .matches(/^[\d]{5}-[\d]{3}$/, 'Informe um CEP válido.')
    .required('Informe o CEP.'),
  city: yup.string().trim().required('Selecione a cidade.'),
  state: yup.object().shape({
    nome: yup.string(),
    sigla: yup
      .mixed()
      .oneOf(
        [
          'AC',
          'AL',
          'AP',
          'AM',
          'BA',
          'CE',
          'DF',
          'ES',
          'GO',
          'MA',
          'MT',
          'MS',
          'MG',
          'PA',
          'PB',
          'PR',
          'PE',
          'PI',
          'RJ',
          'RN',
          'RS',
          'RO',
          'RR',
          'SC',
          'SP',
          'SE',
          'TO',
        ],
        'Selecione o estado.',
      )
      .required('Selecione o estado.'),
  }),
});
const schemaAccountSalesCounter = yup.object().shape({
});


const schemaAddress = yup.object().shape({
  address: yup
    .string()
    .trim()
    .min(4, 'Logradouro muito curto. Favor, informar o logradouro completo.')
    .required('Informe o logradouro.'),
  addressNumber: yup.string().trim().required('Informe o número.'),
  district: yup
    .string()
    .trim()
    .min(4, 'Bairro muito curto. Favor, informar o nome completo do bairro.')
    .required('Informe o bairro.'),
  CEP: yup
    .string()
    .matches(/^[\d]{5}-[\d]{3}$/, 'Informe um CEP válido.')
    .required('Informe o CEP.'),
  city: yup.string().trim().required('Selecione a cidade.'),
  state: yup.object().shape({
    nome: yup.string(),
    sigla: yup
      .mixed()
      .oneOf(
        [
          'AC',
          'AL',
          'AP',
          'AM',
          'BA',
          'CE',
          'DF',
          'ES',
          'GO',
          'MA',
          'MT',
          'MS',
          'MG',
          'PA',
          'PB',
          'PR',
          'PE',
          'PI',
          'RJ',
          'RN',
          'RS',
          'RO',
          'RR',
          'SC',
          'SP',
          'SE',
          'TO',
        ],
        'Selecione o estado.',
      )
      .required('Selecione o estado.'),
  }),
});

const schemaClerk = yup.object().shape({
});


const schemaOwnerStep1 = yup.object().shape({
  CNPJ: yup.string().test('validateCNPJ', 'Informe um CNPJ válido', (value: any) => {
    return cnpj.isValid(value);
  }),
});

const schemaOwnerStep2 = yup.object().shape({
  companyName: yup
    .string()
    .trim(),
  tradeName: yup
    .string()
    .trim(),
  responsibleName: yup
    .string()
    .trim()
    .min(
      4,
      'Nome do responsável muito curto.Favor, informar o nome completo do responsável.',
    ),
  email: yup
    .string()
    .email('Informe um email válido. Ex: exemplo@exemplo.com')
    .required('Informe o email do proprietário.'),
  cellphone: yup
    .string()
    .test(
      'validateBRCellphone',
      'Informe um número de Whatsapp válido.',
      // @ts-ignore
      validateCellphone,
    )
    .required('Informe o Whatsapp do proprietário'),

  CNPJ: yup.string(),

  CPF: yup.string().test('validateCPF', 'Informe um CPF válido.', (value: any) => {
    return cpf.isValid(value);
  }),
  CNAE: yup.string(),
  address: yup
    .string()
    .trim()
    .min(4, 'Logradouro muito curto. Favor, informar o logradouro completo.')
    .required('Informe o logradouro.'),
  addressComplement: yup
    .string()
    .nullable()
    .min(
      4,
      'Complemento muito curto. Favor, detalhar o complemento de endereço.',
    ),
  addressNumber: yup.string().trim().required('Informe o número.'),
  district: yup
    .string()
    .trim()
    .min(4, 'Bairro muito curto. Favor, informar o nome completo do bairro.')
    .required('Informe o bairro.'),
  CEP: yup
    .string()
    .matches(/^[\d]{5}-[\d]{3}$/, 'Informe um CEP válido.')
    .required('Informe o CEP.'),
  city: yup.string().trim().required('Selecione a cidade.'),
  state: yup.object().shape({
    nome: yup.string(),
    sigla: yup
      .mixed()
      .oneOf(
        [
          'AC',
          'AL',
          'AP',
          'AM',
          'BA',
          'CE',
          'DF',
          'ES',
          'GO',
          'MA',
          'MT',
          'MS',
          'MG',
          'PA',
          'PB',
          'PR',
          'PE',
          'PI',
          'RJ',
          'RN',
          'RS',
          'RO',
          'RR',
          'SC',
          'SP',
          'SE',
          'TO',
        ],
        'Selecione o estado.',
      )
      .required('Selecione o estado.'),
  })
});

const schemaFilialUpdate = yup.object().shape({
  tradeName: yup
    .string()
    .trim(),
  responsibleName: yup
    .string()
    .trim()
    .min(
      4,
      'Nome do responsável muito curto.Favor, informar o nome completo do responsável.',
    ).required("Informe o nome do responsável."),
  email: yup
    .string()
    .email('Informe um email válido. Ex: exemplo@exemplo.com')
    .required('Informe o email do responsável.'),
  cellphone: yup
    .string()
    .test(
      'validateBRCellphone',
      'Informe um número de Whatsapp válido.',
      // @ts-ignore
      validateCellphone,
    )
    .required('Informe o Whatsapp do proprietário'),

  CPF: yup.string().test('validateCPF', 'Informe um CPF válido.', (value: any) => {
    return cpf.isValid(value);
  }).required("Informe o CPF do responsável."),
  address: yup
    .string()
    .trim()
    .min(4, 'Logradouro muito curto. Favor, informar o logradouro completo.')
    .required('Informe o logradouro.'),
  addressComplement: yup
    .string()
    .nullable()
    .min(
      4,
      'Complemento muito curto. Favor, detalhar o complemento de endereço.',
    ),
  addressNumber: yup.string().trim().required('Informe o número.'),
  district: yup
    .string()
    .trim()
    .min(4, 'Bairro muito curto. Favor, informar o nome completo do bairro.')
    .required('Informe o bairro.'),
  CEP: yup
    .string()
    .matches(/^[\d]{5}-[\d]{3}$/, 'Informe um CEP válido.')
    .required('Informe o CEP.'),
  city: yup.string().trim().required('Selecione a cidade.'),
  state: yup.object().shape({
    nome: yup.string(),
    sigla: yup
      .mixed()
      .oneOf(
        [
          'AC',
          'AL',
          'AP',
          'AM',
          'BA',
          'CE',
          'DF',
          'ES',
          'GO',
          'MA',
          'MT',
          'MS',
          'MG',
          'PA',
          'PB',
          'PR',
          'PE',
          'PI',
          'RJ',
          'RN',
          'RS',
          'RO',
          'RR',
          'SC',
          'SP',
          'SE',
          'TO',
        ],
        'Selecione o estado.',
      )
      .required('Selecione o estado.'),
  })
});

const schemaChangeOperatorPassword = yup.object().shape({
  oldPassword: yup
    .string()
    .min(6, 'A atual senha de acesso tem no mínimo 6 dígitos.')
    .required('Informe a atual senha de acesso do Proprietário.'),
  password: yup
    .string()
    .min(6, 'A nova senha deve ter no mínimo 6 dígitos.')
    .required('Informe a nova senha de acesso do Proprietário.'),
  passwordConfirmation: yup
    .string()
    .oneOf(
      [yup.ref('password')],
      'Senha de confirmação não coincide com a nova senha.',
    )
    .required('Confirme a nova senha de acesso do Proprietário.'),
});

const schemaGestorCreate = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(
      4,
      'Nome do responsável muito curto. Favor, informar o nome completo do responsável.',
    )
    .required('Informe o nome do responsável pelas vendas no balcão.'),
  cpf: yup.string().test('validateCPF', 'Informe um CPF válido.', (value) => {
    return cpf.isValid(value as string);
  }),
  phone: yup
    .string()
    .test(
      'validateBRCellphone',
      'Informe um número de Whatsapp válido.',
      // @ts-ignore
      validateCellphone,
    )
    .required('Informe o número de Whatsapp do balcão.'),
  email: yup
    .string()
    .email('Informe um email válido. Ex: exemplo@exemplo.com.')
    .required('Informe o email para login do balcão.'),
  password: yup
    .string()
    .min(6, 'A senha deve ter no mínimo 6 dígitos.')
    .required('Informe a senha de acesso.'),
});

const schemaGestorUpdate = yup.object().shape({
  phone: yup
    .string()
    .test(
      'validateBRCellphone',
      'Informe um número de Whatsapp válido.',
      // @ts-ignore
      validateCellphone,
    )
    .required('Informe o número de Whatsapp do balcão.'),
  email: yup
    .string()
    .email('Informe um email válido. Ex: exemplo@exemplo.com.')
    .required('Informe o email para login do balcão.'),
  password: yup
    .string()
    .min(6, 'A senha deve ter no mínimo 6 dígitos.')
});

export default schemaChangeOperatorPassword;



export {
  registerStep,
  schemaSalesCounter,
  schemaSalesCounterCreate,
  schemaAccountSalesCounter,
  schemaOwnerStep1,
  schemaOwnerStep2,
  schemaChangeOperatorPassword,
  schemaClerk,
  schemaAddress,
  schemaFilialUpdate,
  schemaGestorCreate,
  schemaGestorUpdate,
  schemaSalesCounterUpdate
}