import { Tooltip, SxProps, Theme } from "@mui/material";
import { colors } from "theme";

const TooltipUI = ({
    children,
    title,
    sx,
    followCursor = false,
    color
}: {
    children: JSX.Element;
    title: string;
    sx?: SxProps<Theme>,
    followCursor?: boolean,
    color?: string
}) => {
    return (
        <Tooltip
            title={title}
            slotProps={{
                tooltip: {
                    sx: { bgcolor: colors.grey200, color: color ?? colors.black, ...(sx && {sx}) }
                }
            }}
            followCursor={followCursor}>
            {children}
        </Tooltip>
    )
}

export default TooltipUI;