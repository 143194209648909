import Icons from "./icons";

import Lend from "pages/app/lend";
import SelectedTable from "components/selectedTable";
import { Outlet } from "react-router";
import PersonInfos from "components/startLend/personInformation";
import Proposals from "pages/app/proposals";
import Commissions from "pages/app/commissions";
import BranchCorrenpondent from "pages/app/BranchCorrespondent";
import NewFilial from "pages/app/BranchCorrespondent/newFilial";
import Statistics from "pages/app/statistics";
import Indicators from "pages/app/indicators";
import Indicator from "pages/app/indicators/indicato";
import Advertising from "pages/app/advertising";
import Support from "pages/app/support";
import Tutorial from "pages/app/Tutorial"
import Historic from "pages/app/commissions/historic";
import HistoricProposal from "pages/app/commissions/historic/proposals";
import LinkQrCode from "pages/app/qrcode"
import FilialAutoRegister from "pages/app/BranchCorrespondent/autoRegister";
import Gestor from "pages/app/gestor";
import NewGestor from "pages/app/gestor/new";
import DetailsGestor from "pages/app/gestor/details";
import EditFilial from "pages/app/BranchCorrespondent/edit";
import Remarketing from "pages/app/remarketing";
import { MdLeaderboard } from "react-icons/md";

const Navigations = {
    pdv: [
        {
            id: 0,
            title: 'emprestar',
            icon: () => <img src={Icons.IconEmprestimo} alt="icon" id="icon-nav"/>,
            path: 'emprestar',
            permissions: ['pdv', 'sales_counter', 'gestor'],
            component: <Outlet />,
            children: [
                {
                    path: '/app/emprestar/',
                    element: <Lend />
                },
                {
                    path: '/app/emprestar/tabela/',
                    element: <SelectedTable />,
                },
                {
                    path: '/app/emprestar/tabela/proposta',
                    element: <PersonInfos />,
                },
                {
                    path: '/app/emprestar/tutorial',
                    element: <Tutorial />
                },
            ]
        },
        {
            id: 1,
            title: 'link/qr code',
            permissions: ['pdv', 'sales_counter', 'gestor'],
            icon: () => <img src={Icons.IconQRCODE} alt="icon" id="icon-nav"/>,
            path: 'autoemprestimo',
            component: <LinkQrCode />
        },
        {
            id: 2,
            title: 'propostas',
            permissions: ['pdv', 'sales_counter', 'gestor'],
            icon: () => <img src={Icons.IconPROPOSTAS} alt="icon" id="icon-nav"/>,
            path: 'propostas',
            component: <Proposals />
        },
        {
            id: 3,
            title: 'comissões',
            permissions: ['pdv', 'sales_counter', 'gestor'],
            icon: () => <img src={Icons.IconCOMISSÕES} alt="icon" id="icon-nav"/>,
            path: 'comissoes',
            component: <Outlet />,
            children: [
                {
                    path: '/app/comissoes',
                    element: <Commissions />
                },
                {
                    path: '/app/comissoes/historico',
                    element: <Historic />
                },
                {
                    path: '/app/comissoes/historico/:proposal',
                    element: <HistoricProposal />
                },
            ]
        },
        {
            id: 4,
            title: 'Gestores',
            permissions: ['pdv'],
            icon: () => <img src={Icons.IconGESTAO} alt="icon" id="icon-nav"/>,
            path: 'gestores',
            component: <Outlet />,
            children: [
                {
                    path: '/app/gestores',
                    element: <Gestor />
                },
                {
                    path: '/app/gestores/novo-gestor',
                    element: <NewGestor />,
                },
                {
                    path: '/app/gestores/detalhes/:gestorId',
                    element: <DetailsGestor />,
                },
            ]
        },
        {
            id: 5,
            title: 'Filiais',
            permissions: ['pdv', 'gestor'],
            icon: () => <img src={Icons.IconFiliais} alt="icon" id="icon-nav"/>,
            path: 'filiais',
            component: <Outlet />,
            children: [
                {
                    path: '/app/filiais',
                    element: <BranchCorrenpondent />
                },
                {
                    path: '/app/filiais/nova-filial',
                    element: <NewFilial />,
                },
                {
                    path: '/app/filiais/editar-filial',
                    element: <EditFilial />,
                },
                {
                    path: '/app/filiais/auto-cadastro/:token',
                    element: <FilialAutoRegister />,
                }
            ]
        },    
        {
            id: 6,
            title: 'correspondentes',
            permissions: ['pdv', 'gestor'],
            icon: () => <img src={Icons.IconCORRESPONDENTES} alt="icon" id="icon-nav"/>,
            path: 'correspondentes',
            component: <Outlet />,
            children: [
                {
                    path: '/app/correspondentes',
                    element: <Indicators />,
                   
                },
                {
                    path: '/app/correspondentes/:id',
                    element: <Indicator />
                }
            ]
        },
        {
            id: 7,
            title: 'estatística',
            permissions: ['pdv', 'sales_counter', 'gestor'],
            icon: () => <img src={Icons.IconESTATÍSTICA} alt="icon" id="icon-nav"/>,
            path: 'estatistica',
            component: <Statistics />
        },
        {
            id: 8,
            title: 'vaptpost',
            permissions: ['pdv', 'sales_counter', 'gestor'],
            icon: () => <img src={Icons.IconVaptPost} alt="icon" id="icon-nav"/>,
            path: 'vaptpost',
            component: <Advertising />
        },
        {
            id: 9,
            title: 'leads',
            permissions: ['pdv', 'sales_counter', 'gestor'],
            icon: () => <MdLeaderboard color="gray" size={24}/>,
            path: 'leads',
            component: <Remarketing />
        },
        {
            id: 10,
            title: 'dúvidas',
            permissions: ['pdv', 'sales_counter', 'gestor'],
            icon: () => <img src={Icons.IconSuporte} alt="icon" id="icon-nav"/>,
            path: 'suporte',
            component: <Support />
        }
    ]
}

export default Navigations;