import { Check, Close } from "@mui/icons-material";
import { Card, CardContent, CardHeader, Popover, Step, StepLabel, Typography } from "@mui/material";
import useStyles from '../../../../pages/app/proposals/status/styles';
import { useEffect, useState } from "react";
import { ProposalRR } from "types/proposal";
import useGeneral from "context/general";
import useMediasQuerys from "hooks/mediasQuery";

export function StatuSteps({
    label,
    index,
    status: _s,
    proposal: _p
}: {
    label: string,
    index: number,
    status: number,
    proposal: ProposalRR
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const classes = useStyles()
    const [isFailed, setIsFailed] = useState(false)
    const [pointNumber, setPointNumber] = useState(0)
    const [status, setStatus] = useState(_s)
    const [title, setTitle] = useState("")
    const [subtitle, setSubtitle] = useState("")
    const [isCanceled, setIsCanceled] = useState(false)
    const { matchesMaxMobile } = useMediasQuerys()
    const {
        proposal,
    } = useGeneral()

    const statusProposal = (proposal: string) => {
        let status = 0
        switch (proposal) {
            case 'started':
                status = 0
                break;
            case 'preValidationCard':
                status = 1
                break;
            case 'transfeera':
                status = 2
                break;
            case 'face_id':
                status = 3
                break;
            case 'signature':
                status = 4
                break;
            case 'aproved':
                status = 5
                break;
            default:
                break;
        }
        return status
    }

    useEffect(() => {
        if (proposal) updateAllStatus()
    }, [proposal])

    const updateAllStatus = async () => {
        const a = renderTitles()
        if (a) {
            if (a?.title) {
                setTitle(a.title)
            }
            setSubtitle(a.subtitle)
        }
        const c = renderPoint()
        setIsFailed(c.is)
        setPointNumber(c.pointNumber)
        setIsCanceled(c.point === "canceled" && c.is)
        if (c.point === "canceled" && c.is) {
            setSubtitle("Proposta cancelada")
        }
    }

    const handlePopoverOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const renderPoint = () => {
        let body
        if ((proposal ?? _p as ProposalRR).proposal_status === "failed") {
            body = {
                is: true,
                point: "antifraudes",
                pointNumber: 0
            }
            if ((proposal ?? _p as ProposalRR).antifraude_desc === "Não aprovado") {
                body = {
                    is: true,
                    point: "antifraudes",
                    pointNumber: 0
                }
                setStatus(0)
            } else if ((proposal ?? _p as ProposalRR).checkout_status === "failed" || (proposal ?? _p as ProposalRR).checkout_desc.includes("00")) {
                body = {
                    is: true,
                    point: "checkout",
                    pointNumber: 1
                }
                setStatus(1)
            } else if ((proposal ?? _p as ProposalRR).faceid_status === "failed") {
                body = {
                    is: true,
                    point: "faceid",
                    pointNumber: 3
                }
                setStatus(3)
            } else if ((proposal ?? _p as ProposalRR).sign_status === "canceled" || (proposal ?? _p as ProposalRR).sign_status === "failed") {
                body = {
                    is: true,
                    point: "signature",
                    pointNumber: 4
                }
                setStatus(4)
            } else {
                body = {
                    pointNumber: 10,
                    is: true,
                    point: "canceled"
                }
            }
        } else {
            body = {
                point: "canceled",
                is: false,
                pointNumber: 0
            }
            setStatus(statusProposal((proposal ?? _p as ProposalRR).proposal_status))
        }
        return body
    }

    const labels = {
        'Aguardando preencher': 'Aguardando cliente informar os dados.',
        'Aguardando capturar': 'Aguardando o cliente enviar as fotos para validação.',
        'Aguardando assinatura': 'Aguardando o cliente assinar a CCB(Cédula de crédito bancário)',
        'Aguardando validação': 'Aguardando as fotos do cliente serem validadas (tempo estimado de 3 minutos a 1 hora). Caso as fotos sejam recusadas, o cliente pode reenviar as fotos.',
        'Aguardando informar': 'Aguardando o cliente informar os dados.',
        'Aguardando digitar': 'Aguardando o cliente informar os dados.',
        'Incompleta': 'Proposta em andamento.',
        'Não aprovado': 'Os dados enviados não foram aprovados na validação.',
        'Não aprovada': 'Proposta não aprovada.',
        'Aprovado': 'Etapa concluída com sucesso.',
        'Aprovada': (proposal ?? _p as ProposalRR).ted_status === "paid" ? 'Pagamento efetuado.' : "Aguardando pagamento",
        'Pré validado': 'Cartão de crédito aprovado para operação.',
        '2° Passo': (proposal ?? _p as ProposalRR).proposal_status === "preValidationCard" ? 'Aguardando os dados do cartão de crédito' : 'Aguardando o passo anterior ser concluído',
        '3° Passo': (proposal ?? _p as ProposalRR).proposal_status === "face_id" ? 'Aguardando a coleta de fotos' : 'Aguardando o passo anterior ser concluído',
        '4° Passo': 'Aguardando o passo anterior ser concluído',
        'waiting': 'O valor será creditado entre 1 e 48 horas úteis.',
        'failed': 'O valor será creditado entre 1 e 48 horas úteis.',
        'paid': 'Transferência enviada.',
        'Aguardando desafio 3ds': 'Aguardando confirmação da compra.',
        'Cancelado': 'Assinatura cancelada manualmente ou tempo limite esgotado.',
        'account': ((proposal ?? _p as ProposalRR).proposal_status === "face_id" || (proposal ?? _p as ProposalRR).proposal_status === "signature" || (proposal ?? _p as ProposalRR).proposal_status === "aproved") ? 'Etapa concluída com sucesso.' : (proposal ?? _p as ProposalRR).proposal_status === "transfeera" ? 'Aguardando o preenchimento dos dados bancários' : 'Aguardando o passo anterior ser concluído',
    }

    const labelsByIndex = [
        "antifraude_desc",
        "checkout_desc",
        "account",
        "face_id_desc",
        "sign_desc",
        "proposal_desc",
    ]

    const renderFailure = () => {
        if ((proposal ?? _p as ProposalRR).antifraude_desc === "Não aprovado") {
            return `A proposta não passou no antifraudes`
        } else if ((proposal ?? _p as ProposalRR).checkout_status === "failed" || (proposal ?? _p as ProposalRR).checkout_desc === "00 | Cartão de crédito não aprovado") {
            return `A proposta não passou pelo checkout`
        } else if ((proposal ?? _p as ProposalRR).faceid_status === "failed") {
            return `A proposta não passou pela validação das fotos`
        } else if ((proposal ?? _p as ProposalRR).sign_status === "canceled") {
            return "Assinatura cancelada manualmente ou tempo limite esgotado."
        } else {
            return "Proposta não aprovada."
        }
    }

    const labelsTransfeera = {
        'transfeera': 'Aguardando validação.',
        'preValidationCard': 'Validada',
        'face_id': 'Validada',
        'signature': 'Validada',
        'aproved': 'Validada',
        'failed': 'Aguardando validação.'
    }
    //@ts-ignore
    const renderTitles = (): {
        title: string,
        subtitle: string
    } => {
        //@ts-ignore
        let value = proposal[labelsByIndex[index]]
        switch (labelsByIndex[index]) {
            case 'antifraude_desc':
                if (!value) {
                    break
                } else {
                    return {
                        //@ts-ignore
                        subtitle: (proposal ?? _p as ProposalRR).proposal_status === "failed" && (proposal ?? _p as ProposalRR).antifraude_desc !== "Não aprovado" && (proposal ?? _p as ProposalRR).antifraude_desc !== "Aprovado" ? renderFailure() : labels[value],
                        title: ""
                    }
                }
            case 'checkout_desc':
                if (!value) {
                    break;
                } else {
                    if (value?.includes(' | ')) {
                        if (value === "00 | Cartão de crédito não aprovado") {
                            return {
                                title: "Cartão de crédito não aprovado",
                                subtitle: "O cartão não pertence ao CPF do cliente cadastrado"
                            }
                        } else if (value?.includes("3ds")) {
                            return {
                                title: "Cartão de crédito não aprovado. O cliente não confirmou a compra na PGW*CONCEPT",
                                subtitle: "O cliente não confirmou a compra na PGW*CONCEPT"
                            }
                        } else {
                            const split = value.split(' | ')
                            return {
                                title: split[0] + " - " + split[1],
                                subtitle: split[2]
                            }
                        }
                    } else if (value === "Aguardando desafio") {
                        return {
                            title: "AGUARDANDO A VALIDAÇÃO DO CARTÃO",
                            subtitle: "Por segurança enviamos um valor simbólico na fatura do cartão, favor verificar e nos informar."
                        }
                    } else if (value === "Aguardando desafio 3ds") {
                        return {
                            title: "DESAFIO 3DS",
                            subtitle: "Aguardando confirmação da compra."
                        }
                    } else {
                        return {
                            //@ts-ignore
                            subtitle: (proposal ?? _p as ProposalRR).proposal_status === "failed" && (proposal ?? _p as ProposalRR).checkout_status !== "failed" && (proposal ?? _p as ProposalRR).checkout_status !== "confirmed" ? renderFailure() : (labels[value]),
                            title: ""
                        }
                    }
                }
            case 'face_id_desc':
                if (!value) {
                    break
                } else {
                    return {
                        //@ts-ignore
                        subtitle: (proposal ?? _p as ProposalRR).proposal_status === "failed" && (proposal ?? _p as ProposalRR).faceid_status !== "failed" && (proposal ?? _p as ProposalRR).face_id_desc !== "Aprovado" ? renderFailure() : labels[value],
                        title: ""
                    }
                }
            case 'sign_desc':
                if (!value) {
                    break
                } else {
                    return {
                        //@ts-ignore
                        subtitle: (proposal ?? _p as ProposalRR).proposal_status === "failed" && (proposal ?? _p as ProposalRR).sign_status !== "canceled" && (proposal ?? _p as ProposalRR).sign_status !== "signed" ? renderFailure() : labels[value],
                        title: ""
                    }
                }
            case 'proposal_desc':
                if (!value) {
                    break
                } else {
                    return {
                        //@ts-ignore
                        subtitle: (proposal ?? _p as ProposalRR).proposal_status === "failed" ? renderFailure() : labels[value],
                        title: ""
                    }
                }
            case 'ted_status':
                if (value.split('|')[0].trim() === "null") {
                    return {
                        //@ts-ignore
                        subtitle: (proposal ?? _p as ProposalRR).proposal_status === "failed" ? renderFailure() : value.split('|')[1].trim() === "aproved" ? "Aguardando pagar a proposta" : "Aguardando aprovar a proposta",
                        title: ""
                    }
                } else {
                    return {
                        //@ts-ignore
                        subtitle: (proposal ?? _p as ProposalRR).proposal_status === "failed" ? renderFailure() : labels[value.split('|')[0].trim()],
                        title: ""
                    }
                }
            case 'proposal_status':
                if (!value) {
                    break
                } else {
                    return {
                        //@ts-ignore
                        subtitle: labelsTransfeera[value],
                        title: ""
                    }
                }
            case 'account':
                return {
                    //@ts-ignore
                    subtitle: (proposal ?? _p as ProposalRR).proposal_status !== 'failed' ? labels['account'] : renderFailure(),
                    title: ""
                }
            default:
                return {
                    //@ts-ignore
                    subtitle: "",
                    title: "STATUS não identificado"
                }
        }
    }

    return (
        <Step
            key={label}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            sx={{
                mt: 2
            }}
            className={(isFailed === true && index === pointNumber) || isCanceled ? "lineError" : status >= index ? "lineChecked" : "lineNotSelected"}
        >
            <StepLabel
                sx={{
                    px: 1,
                }}
                className={matchesMaxMobile ? ((isFailed === true && index === pointNumber) || isCanceled ? "labelMobileCanceled" : status >= index ? "labelMobileChecked" : "labelMobileNotSelected") : ""}
                icon={
                    <div
                        className={(isFailed === true && index === pointNumber) || isCanceled ? "stepIndicatorError" : status > index || (status === 5 && (proposal ?? _p as ProposalRR).ted_status === "paid") ? "stepIndicatorChecked" : status === index ? "stepIndicatorSelected" : "stepIndicatorNotSelected"}>
                        {(isFailed === true && index === pointNumber) || isCanceled ? <Close sx={{ color: 'white' }} /> : status > index ?
                            <Check sx={{ color: 'white' }} /> :
                            status === 5 && (proposal ?? _p as ProposalRR).ted_status === "paid" ? <Check sx={{ color: 'white' }} /> : index + 1}</div>
                }
                optional={
                    matchesMaxMobile && <Typography sx={{
                        fontSize: '12px',
                        fontWeight: '400',
                        pl: '12px',
                        color: '#000'
                    }}>{subtitle}</Typography>
                }
            >{label}</StepLabel>
            {!matchesMaxMobile && <Popover
                elevation={3}
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Card>
                    {title && <CardHeader
                        title={title}
                    />}
                    <CardContent>
                        <Typography variant="body2">{subtitle}</Typography>
                    </CardContent>
                </Card>
            </Popover>}

        </Step>

    )
}