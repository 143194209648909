import { useState, useEffect } from "react"
import { Button, Grid, Typography } from "@mui/material"
import { formatCurrency } from "@utils/format"
import Card from "components/@ui/card"
import RemoteServices from "service"
import TooltipUI from "components/@ui/Tooltip"
import { useNavigate } from "react-router"
import useMediasQuerys from "hooks/mediasQuery"
import useAuth from "context/auth"

export default function LadingCards({
    onClickCommissionRequest
}: {
    onClickCommissionRequest: () => void
}) {
    const {
        defaultUser,
        User
    } = useAuth()
    const navigate = useNavigate()

    const [availableCommission, setAvailableCommission] = useState<number>(0)
    const [totalBorrowed, setTotalBorrowed] = useState<number>(0)
    const [pendingProposals, setPendingProposals] = useState<number>(0)

    const { matchesMaxMobile } = useMediasQuerys()

    const getAvailableCommissions = () => {
        RemoteServices.commissions.Available()
            .then(({
                response
            }) => {
                if (response?.amount) {
                    setAvailableCommission(response.amount)
                }
            })
    }

    const getProposalAnalitics = () => {
        RemoteServices.proposals.ProposalBI()
            .then(({
                response
            }) => {
                if (response?.total_borrowed) {
                    setTotalBorrowed(response?.total_borrowed?.month)
                }
                if (response?.pending_proposals) {
                    setPendingProposals(response?.pending_proposals?.month)
                }
            })
    }

    useEffect(() => {
        getAvailableCommissions()
        getProposalAnalitics()
    }, [])

    return (
        <Grid
            container
            gap={3}
            flexDirection={"row"}
        >
            <Card
                sx={{
                    maxWidth: matchesMaxMobile ? '100%' : 300,
                    width: '100%'
                }}
                sxBox={{
                    p: 2,
                    maxWidth: matchesMaxMobile ? '100%' : 300
                }}
                bgcolor="primary"
            >
                <Grid container maxWidth={matchesMaxMobile ? '100%' : 400}>
                    <div>
                        <Typography variant="h5" fontWeight={"bold"}>{formatCurrency(totalBorrowed)}</Typography> <br />
                        <Typography variant="subtitle1" sx={{ mt: -2 }}>Total emprestado no mês</Typography>
                    </div>
                </Grid>
            </Card>
            <Card
                sx={{
                    maxWidth: matchesMaxMobile ? '100%' : 300,
                    width: '100%'
                }}
                sxBox={{
                    p: 2
                }}
                bgcolor="Secondary"
            >
                <Grid container gap={2}>
                    <Typography variant="h5" fontWeight={"bold"} color={availableCommission < 0 ? 'red' : 'inherit'}>{formatCurrency(availableCommission)}</Typography>
                    <Typography variant="subtitle1">Comissões a receber <br />
                        <TooltipUI title={availableCommission === 0 ? 'Não há comissões para receber' : ''}>
                            <div>
                            {User?.space !== "gestor" ?
                        <Button
                            disabled={availableCommission <= 0}
                            onClick={onClickCommissionRequest}
                            variant="text"
                            color="inherit"
                            sx={{ p: 0, textDecoration: 'underline' }}>
                            Solicitar comissão agora
                        </Button>
                        :
                        defaultUser?.permissions?.permissions.includes("requestCommission") && <Button
                            disabled={availableCommission <= 0}
                            onClick={onClickCommissionRequest}
                            variant="text"
                            color="inherit"
                            sx={{ p: 0, textDecoration: 'underline' }}>
                            Solicitar comissão agora
                        </Button>
                    }
                            </div>
                        </TooltipUI>
                    </Typography>
                </Grid>
            </Card>
            <Card
                sxBox={{
                    p: 2
                }}
                bgcolor="warning"
                sx={{
                    maxWidth: matchesMaxMobile ? '100%' : 300,
                    width: '100%'
                }}
            >
                <Grid container gap={2} sx={{ cursor: 'pointer' }} onClick={() => navigate('/app/propostas?status=pending')}>
                    <Typography variant="h5" fontWeight={"bold"}>{pendingProposals}</Typography>
                    <Typography variant="subtitle1">Propostas Pendentes</Typography>
                </Grid>
            </Card>
        </Grid>
    )
}