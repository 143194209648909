import React, { useEffect, useState } from "react"
import { Box, Button, Grid, TextField, Typography } from "@mui/material"
import AccordionUI from "components/@ui/accordion"
import Breadcrumb from "components/breadcrumbs"
import { colors } from "theme"
import Icons from "utils/icons"
import AllQuestions from "utils/perguntas"
import useAuth from "context/auth"
import { FacebookPixel } from "components/facebookPixel"
import BannerQuestion from 'assets/png/Banner question.png'
import { saveAs } from 'file-saver'
import TooltipUI from "components/@ui/Tooltip"

const Support = () => {
    const { User } = useAuth()
    const [search, setrSearch] = useState('')
    const [questions, setQuestions] = useState(AllQuestions)
    const autoEmprestimoTitle = "Consigo vincular meu código Pixel do Facebook aos meus Links e qr codes de Autoempréstimo?"
    const iphneTitle = "Quais são as regras para a premiação AYUDE?"
    const [openFacebookPixelModal, setOpenFacebookPixelModal] = useState(false)

    useEffect(() => {
        if (User?.space === "sales_counter") {
            var newQuestions = questions.filter((item) => item.title !== "A Ayude disponibiliza API para integração?")
            setQuestions(newQuestions)
        }
    }, [])

    const downloadImage = (img: string, name: string) => {
        saveAs(img, name)
    }


    return (
        <Grid p={2}>
            <Breadcrumb />
            <Grid p={2} mt={2}>
                <Grid
                    container
                    gap={2}
                    py={2}
                >
                    <img
                        src={Icons.IconSuporte}
                        alt="icon"
                        width={35}
                        style={{ filter: 'brightness(0)' }}
                    />
                    <Typography
                        textTransform={"uppercase"}
                        color={colors.black}
                        variant="h5"
                        fontWeight={"bold"}>DÚVIDAS</Typography>

                </Grid>
                <Grid container gap={2} mt={5} flexDirection={"column"}>
                    <TextField
                        value={search}
                        onChange={(e) => setrSearch(e.target.value)}
                        label="Buscar" sx={{ bgcolor: 'transparent', maxWidth: 700 }} />
                    {questions.map((e) => {
                        if (search.length > 0 &&
                            (!e.title.toUpperCase().includes(search?.toUpperCase()) &&
                                !e.content.toUpperCase().includes(search?.toUpperCase()))
                        ) {
                            return <></>
                        } else {
                            return (
                                <AccordionUI key={e.title} title={e.title}>
                                    <Grid>
                                        <div dangerouslySetInnerHTML={{ __html: e.content }} />
                                    </Grid>
                                </AccordionUI>
                            )
                        }
                    })}
                    {iphneTitle.toUpperCase().includes(search.toUpperCase()) &&
                        <AccordionUI key={'iphone'} title={iphneTitle}>
                            <Grid container xs={12}>
                                <TooltipUI title="Clique para baixar" followCursor>
                                    <img
                                        src={BannerQuestion}
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => downloadImage(BannerQuestion, "Banner premiação AYUDE")}
                                    />
                                </TooltipUI>
                            </Grid>
                        </AccordionUI>
                    }

                    {autoEmprestimoTitle.toUpperCase().includes(search.toUpperCase()) &&
                        <AccordionUI key={'autoemprestimo'} title={autoEmprestimoTitle}>
                            <Grid>
                                <div>
                                    Sim, basta clicar abaixo e vincular o seu código
                                    <br />
                                    <Button onClick={() => {
                                        setOpenFacebookPixelModal(true)
                                    }}>Vincular código</Button>
                                </div>
                            </Grid>
                        </AccordionUI>
                    }
                    <AccordionUI
                        defaultExpanded
                        title="Não encontrou sua resposta?">
                        <Grid p={2}>
                            <Typography>Entre em contato conosco através de um dos nossos canais de atendimento:</Typography>
                            <Grid container py={2} gap={3}>
                                <Box>
                                    <Typography fontWeight="bold">E-mail:</Typography>
                                    <Button
                                        component="a"
                                        href="mailto:contato@ayude.com.br"
                                        color="inherit"
                                        sx={{ textDecoration: 'underline', p: 0, m: 0 }}>contato@ayude.com.br</Button>
                                </Box>
                                <Box>
                                    <Typography fontWeight="bold">WhatsApp:</Typography>
                                    <Button
                                        color="inherit"
                                        component="a"
                                        target="_blank"
                                        href="https://api.whatsapp.com/send/?phone=5541992859597"
                                        sx={{ textDecoration: 'underline', p: 0, m: 0 }}>
                                        +55 41 9 9285-9597
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </AccordionUI>
                </Grid>
            </Grid>
            <FacebookPixel
                openDialogPixel={openFacebookPixelModal}
                onClickButton={() => {
                    setOpenFacebookPixelModal(false)
                }}
            />
        </Grid>
    )
}

export default Support;