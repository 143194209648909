import { Button, Grid, IconButton, TableCell, Typography, Select, FormControl, TextField, MenuItem, InputLabel, InputAdornment, CircularProgress, OutlinedInput, Stack } from '@mui/material';
import Breadcrumb from 'components/breadcrumbs';
import Icons from 'utils/icons';
import { colors } from 'theme';
import TableUI, { CustomTableRow, TableCellCusttom } from 'components/@ui/table';
import { useEffect, useRef, useState } from 'react';
import RemoteServices from 'service';
import { FiliaisProps } from 'types/filiais';
import { dataBR } from '@utils/format';
import ChipStatus from 'components/@ui/status';
import { BiSolidPencil } from 'react-icons/bi';
import { MdEdit, MdMonetizationOn, MdTableView } from 'react-icons/md';
import { useNavigate } from 'react-router';
import CommissionOnline from './commissionOline';
import defaultTablesOnline from 'utils/tablesOnline';
import Toast from 'components/@ui/toast';
import Managetables from './managetables';
import { FiArrowDown } from 'react-icons/fi';
import useMediasQuerys from 'hooks/mediasQuery';

import { TablePagination } from '@material-ui/core';
import { Close, Search } from '@mui/icons-material';
import TooltipUI from 'components/@ui/Tooltip';
import { CNPJMaskCustom, CPFMaskCustom, CellphoneMaskCustom } from '../../../components/@ui/masked'

import ExcelIcon from 'assets/png/EXCEL.png';
import Axios from 'axios';
import URLSBases from 'utils/urlBases';
import storageLocales from 'utils/storageLocales';

import LinkIcon from '@mui/icons-material/Link';
import useAuth from 'context/auth';
import { BsCheck2Circle } from 'react-icons/bs';
import { ImBlocked } from 'react-icons/im';
import { SelectUI } from 'components/@ui/select';
import { TableListGestorsUI } from 'components/@ui/tableList';

export default function BranchCorrenpondent() {

    const { matchesMaxMobile } = useMediasQuerys()

    const {
        User,
        defaultUser
    } = useAuth()

    const [tablesOnline, setTablesOnline] = useState(defaultTablesOnline)

    const navigate = useNavigate()

    const [rowsPerPage, setRowsPerPage] = useState<number>(10)

    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)

    const [listBranch, setlistBranch] = useState<FiliaisProps['data'][] | null>(null)

    const [salesCounterSelected, setSalesCounterSelected] = useState<FiliaisProps['data'] | null>(null)

    const [saleTablesSalesCounter, setSaleTablesSalesCounter] = useState<{ sale_table: string, active: boolean }[] | null>(null)

    const [allowedTables, setAllowedTables] = useState<{ sale_table: string }[] | null>(null)

    const [openDialogTables, setOpenDialogTables] = useState(false)

    const [openDialogOnlineCommission, setOpenDialogOnlineCommission] = useState(false)

    const [userID, setUserID] = useState('')

    const anchorRef = useRef<HTMLDivElement>(null);

    const [open, setOpen] = useState<number | null>(null);

    const [openSearch, setOpenSearch] = useState<boolean>(false)

    const listFilters = [
        {
            label: "Nome do Responsável",
            name: "responsible_name"
        },
        {
            label: "E-mail",
            name: "email"
        },
        {
            label: "CPF",
            name: "responsible_cpf"
        },
        {
            label: "CNPJ",
            name: "cnpj"
        },
        {
            label: "Whatsapp",
            name: "cellphone"
        }
    ]

    const [search, setSearch] = useState("")
    const [filterParam, setFilterParam] = useState("responsible_name")
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)


    useEffect(() => {
        getSalesCounters()
    }, [page, rowsPerPage])

    const getSalesCounters = async (clear: boolean = false) => {
        let params: {
            limit: number,
            page: number,
            responsible_name?: string,
            email?: string,
            responsible_cpf?: string,
            cnpj?: string,
            cellphone?: string,

        } = {
            limit: rowsPerPage,
            page
        }
        if (!clear && search) {
            //@ts-ignore
            params[filterParam] = search
            setLoading(true)
        }
        if (clear) {
            params.page = 1
            params.limit = 10
            setRowsPerPage(10)
            setPage(1)
        }
        await RemoteServices.filiais.List(params)
            .then(({ response }) => {
                if (response?.data) {
                    setlistBranch(response.data)
                    setTotal(response?.meta?.total_items)
                }
            }).finally(() => setLoading(false))
    }

    const download = async () => {
        setLoadingDownload(true)
        Axios({
            url: URLSBases.utils + "/sales-counters",
            params: {
                page: 1,
                limit: total,
                export: true,
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem(storageLocales.TCtoken)}`
            },
            responseType: 'blob'
        }).then((res) => {
            const fileUrl = URL.createObjectURL(res.data);
            const tempLink = document.createElement('a');
            tempLink.href = fileUrl;
            tempLink.setAttribute('download', 'filiais.xlsx');
            tempLink.click();
            document.body.removeChild(tempLink);
            URL.revokeObjectURL(fileUrl);
        }).finally(() => setLoadingDownload(false))
    }

    const getSalesTables = async (sales_counter_id: number) => {
        RemoteServices.proposals.SalesTable({
            userid: sales_counter_id
        })
            .then(({ response }) => {
                if (response?.result) {
                    setSaleTablesSalesCounter(response?.result?.filter((item: any) => Number(item.sale_table.split('on-')[1]) <= 15))
                } else {
                    Toast({
                        type: "error"
                    }, response?.error ?? response?.message ?? "Falha ao buscar tabelas da FILIAL")
                }
            })
    }

    const openDialogTable = async (sales_counter: FiliaisProps['data']) => {
        await getSalesTables(sales_counter.sales_counter.id)
        setSalesCounterSelected(sales_counter)
        setOpenDialogTables(true)
    }

    const openDialogCommission = async (sales_counter: FiliaisProps['data']) => {
        setSalesCounterSelected(sales_counter)
        await getTax(sales_counter.sales_counter.id)
    }

    function getUserId(id: number) {
        return new Promise((resolve, reject) => {
            RemoteServices.filiais.SalesTableAdmin({ userid: id })
                .then(async ({ response, status }) => {
                    if (status === 200) {
                        const user_id = await response?.users[0][0].user_id
                        setUserID(user_id)
                        resolve(user_id)
                    } else {
                        Toast({
                            type: "error"
                        }, response?.message ? response?.message.replace("esse balcão", "essa matriz") : "Falha ao buscar dados da FILIAL")
                    }
                })
        })
    }


    const getTax = async (sales_counter_id: number) => {
        const userID = await getUserId(sales_counter_id)
        const array: Array<any> = []
        let error = false
        await RemoteServices.proposals.CommissionTax().then(({ response }) => {
            const defaultTax: Array<any> = response?.result
            defaultTablesOnline.forEach((i) => {
                let obj = i
                defaultTax.forEach((item: any) => {
                    if (i.table === item.sale_table) {
                        obj.max_commission_rate = item.commission_tax
                    }
                })
                array.push(obj)
            })

        }).catch(() => {
            Toast({ type: 'error' }, 'Falha ao buscar taxa de comissão da Filial')
            error = true
        })
        const lastArray: any = []
        if (!error) {
            await RemoteServices.proposals.CommissionTax(userID as string).then(({ response }) => {
                const existingTables = response?.result
                array.forEach((i) => {
                    let obj = i
                    obj.commission_rate = 0
                    existingTables.forEach((item: any) => {
                        if (i.table === item.sale_table) {
                            obj.commission_rate = item.commission_rate
                        }
                    })
                    lastArray.push(obj)
                })
            }).catch(() => {
                Toast({ type: 'error' }, 'Falha ao buscar taxa de comissão da Filial')
                error = true
            })
        }
        if (!error) {
            setTablesOnline(lastArray)
            setOpenDialogOnlineCommission(true)
        }

    }

    const getAllowedTables = async () => {
        RemoteServices.proposals.SalesTable().then(({ response }) => {
            setAllowedTables(response?.result)
        })
    }

    const generateToken = async () => {
        await RemoteServices.filiais.GenerateToken().then(({ response, status }) => {
            if (status === 200) {
                navigator.clipboard.writeText(`${window.location.href}/auto-cadastro/${response.result}`)
                Toast({
                    type: 'success'
                }, 'Link para auto cadastro de Filial copiado para área de transferência!')
            } else {
                Toast({
                    type: 'error'
                }, 'Falha ao gerar link de auto cadastro de Filial!')
            }
        })
    }

    const handleActive = async (active: boolean, id: number) => {
        RemoteServices.filiais.SalesCounterUpdate({
            id: id.toString(),
            data: {
                active: active
            }
        }).then(({ response, status }) => {
            if (status === 200) {
                Toast({
                    type: 'success',
                }, `Filial ${active ? 'ativada' : 'desativada'}!`)
            } else {
                Toast({
                    type: 'error',
                }, `Falha ao ${active ? 'ativar' : 'desativar'} filial!`)
            }
        })
    }

    useEffect(() => {
        getAllowedTables()
    }, []);

    return (
        <Grid p={2}>
            <Breadcrumb />
            <Grid p={2} mt={2}>
                <Grid
                    container
                    gap={2}
                    py={2}
                    justifyContent={"space-between"}
                >
                    <div>
                        <Grid container gap={2}>
                            <img
                                src={Icons.IconFiliais}
                                alt="icon"
                                width={25}
                                style={{ filter: 'brightness(0)' }}
                            />
                            <Typography
                                textTransform={"uppercase"}
                                color={colors.black}
                                variant="h5"
                                fontWeight={"bold"}>Correspondente filial</Typography>
                        </Grid>
                    </div>
                    <Grid
                        item
                        display={"flex"}
                        gap={2}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                        flexWrap={matchesMaxMobile ? "wrap" : "nowrap"}
                    >
                        {!openSearch ?
                            <TooltipUI title='Pesquisar'>
                                <IconButton
                                    sx={{
                                        width: 55,
                                        height: 55,
                                        ":hover": {
                                            color: colors.primary
                                        }
                                    }}
                                    onClick={() => setOpenSearch(!openSearch)}
                                >
                                    <Search />
                                </IconButton >
                            </TooltipUI>
                            :
                            <Grid container display={"flex"} gap={2} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"}>
                                <Grid item width={filterParam === "responsible_name" ? (350 - 55) : (250 - 55)}>
                                    <FormControl fullWidth>
                                        <InputLabel id="bi-filter">Pesquisar por</InputLabel>
                                        <Select
                                            labelId="bi-filter"
                                            id="filter"
                                            value={filterParam}
                                            label="Pesquisar por"
                                            onChange={(e) => {
                                                setFilterParam(e.target.value as string)
                                                setSearch("")
                                            }}
                                        >
                                            {listFilters.map((item) => <MenuItem value={item.name}>{item.label}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Stack direction={"row"}>
                                    <Grid item width={filterParam === "responsible_name" ? (matchesMaxMobile ? (350 - 110) : 350) : 250}>
                                        {filterParam === "responsible_name" || filterParam === "email" ? <TextField
                                            fullWidth
                                            label={`Digite o ${listFilters?.find((item) => item.name === filterParam)?.label}`}
                                            value={search}
                                            type={filterParam === "responsible_name" ? "text" : "email"}
                                            onChange={(e) => setSearch(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter" || e.keyCode === 13) {
                                                    getSalesCounters()
                                                }
                                            }}

                                            InputProps={{
                                                endAdornment: loading && <CircularProgress size={24} />
                                            }}
                                        /> :
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                            >
                                                <InputLabel htmlFor={filterParam}>
                                                    {`Digite o ${listFilters?.find((item) => item.name === filterParam)?.label}`}
                                                </InputLabel>
                                                <OutlinedInput
                                                    id={filterParam}
                                                    label={`Digite o ${listFilters?.find((item) => item.name === filterParam)?.label}`}
                                                    inputComponent={filterParam === "cellphone" ? CellphoneMaskCustom : filterParam === "responsible_cpf" ? CPFMaskCustom : CNPJMaskCustom}
                                                    value={search}
                                                    onChange={(event) => {
                                                        setSearch(event.target.value);
                                                    }}
                                                    aria-describedby="component-error-cellphone"
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter" || e.keyCode === 13) {
                                                            getSalesCounters()
                                                        }
                                                    }}
                                                    endAdornment={loading && <CircularProgress size={24} />}
                                                />
                                            </FormControl>
                                        }
                                    </Grid>
                                    <TooltipUI title='Pesquisar'>
                                        <IconButton
                                            sx={{
                                                width: 55,
                                                height: 55,
                                                ":hover": {
                                                    color: colors.primary
                                                }
                                            }}
                                            onClick={() => {
                                                getSalesCounters()
                                            }}
                                        >
                                            <Search />
                                        </IconButton >
                                    </TooltipUI>
                                    <TooltipUI title='Limpar pesquisa'>
                                        <IconButton
                                            sx={{
                                                width: 55,
                                                height: 55,
                                                ":hover": {
                                                    color: colors.primary
                                                }
                                            }}
                                            onClick={() => {
                                                setSearch("")
                                                getSalesCounters(true)
                                                setOpenSearch(!openSearch)
                                            }}
                                        >
                                            <Close />
                                        </IconButton >
                                    </TooltipUI>
                                </Stack>
                            </Grid>
                        }
                        <Grid
                            container
                            display={"flex"}
                            gap={2}
                            justifyContent={"flex-start"}
                            alignItems={"flex-start"}
                            flexDirection={"row"}
                        >
                            <Grid item minWidth={loadingDownload ? 224 : 200}>
                                <Button
                                    startIcon={<img src={ExcelIcon} />}
                                    sx={{
                                        bgcolor: "#158249",
                                        color: "white",
                                        p: 1.8,
                                        ":hover": {
                                            bgcolor: 'rgba(21, 130, 73, 0.9)',
                                        },
                                        ":disabled": {
                                            color: 'white'
                                        }
                                    }}
                                    disabled={loadingDownload || total === 0}
                                    onClick={download}
                                    endIcon={loadingDownload && <CircularProgress size={24} style={{ color: 'white' }} />}
                                >Exportar para Excel</Button>
                            </Grid>
                            {User?.space === "gestor" && defaultUser?.permissions?.permissions.includes("createSalesCounter") &&
                                <><Button
                                    disableElevation
                                    onClick={() => navigate('/app/filiais/nova-filial')}
                                    sx={{ p: 2, minWidth: 200, textTransform: 'none', height: 56.5 }}
                                    variant="contained">Criar nova filial agora</Button>
                                    <Button
                                        disableElevation
                                        onClick={generateToken}
                                        sx={{ p: 2, minWidth: 206, textTransform: 'none', height: 56.5 }}
                                        startIcon={<LinkIcon />}
                                        variant="contained">Gerar link para criar filial</Button></>
                            }
                            {User?.space === "pdv" &&
                                <><Button
                                    disableElevation
                                    onClick={() => navigate('/app/filiais/nova-filial')}
                                    sx={{ p: 2, minWidth: 200, textTransform: 'none', height: 56.5 }}
                                    variant="contained">Criar nova filial agora</Button>

                                    <Button
                                        disableElevation
                                        onClick={generateToken}
                                        sx={{ p: 2, minWidth: 206, textTransform: 'none', height: 56.5 }}
                                        startIcon={<LinkIcon />}
                                        variant="contained">Gerar link para criar filial</Button></>}

                        </Grid>

                    </Grid>
                </Grid>
                {
                    matchesMaxMobile ? listBranch && listBranch.map((row, i) => (
                        <Grid
                            p={1}
                            width="100%"
                            border={`1px solid ${colors.grey400}`}
                            my={2}
                            borderRadius={2}
                            bgcolor="white">
                            <Grid py={1} container borderBottom={`1px solid ${colors.grey400}`}>
                                <Grid item fontWeight="bold">
                                    {row.sales_counter.identification_name}
                                </Grid>
                            </Grid>
                            <Grid pt={1} container fontSize={12} color={colors.grey600} direction="column">
                                <Grid item container flexDirection={'row'} gap={1} alignItems={"center"}>
                                    <Typography fontWeight="bold">ID: </Typography>{row.sales_counter.id}
                                </Grid>
                                <Grid item container flexDirection={'row'} gap={1} alignItems={"center"}>
                                    <Typography fontWeight="bold">E-mail: </Typography>{row.sales_counter.email}
                                </Grid>
                                <Grid item container flexDirection={'row'} gap={1} alignItems={"center"}>
                                    <Typography fontWeight="bold">WhatsApp: </Typography>{row.sales_counter.cellphone}
                                </Grid>
                            </Grid>
                            <Grid pt={2} container borderTop={`1px solid ${colors.grey400}`}>
                                <Grid xs={7}>
                                    <SelectUI
                                        key={row.sales_counter.id}
                                        identifier={row.sales_counter.id}
                                        value={row.sales_counter.active}
                                        options={[
                                            {
                                                color: colors.success800,
                                                label: 'ATIVO',
                                                value: true,
                                                icon: <BsCheck2Circle color={colors.success800} size={22} style={{
                                                    minWidth: 22
                                                }} />
                                            },
                                            {
                                                color: colors.error,
                                                label: 'INATIVO',
                                                value: false,
                                                icon: <ImBlocked color={colors.error} size={20} style={{
                                                    minWidth: 20
                                                }} />
                                            },
                                        ]}
                                        onChange={(value: any) => {
                                            console.log(value, row.sales_counter)
                                            handleActive(value, row.sales_counter.id)
                                        }}
                                    />
                                </Grid>
                                <Grid container gap={1} xs={5}>
                                    {User?.space !== "gestor" &&
                                        <IconButton onClick={() => openDialogCommission(row)}>
                                            <MdMonetizationOn color={colors.black} />
                                        </IconButton>
                                    }
                                    {User?.space === "gestor" && defaultUser?.permissions?.permissions.includes("updateSalesCounterCommission") &&
                                        <IconButton onClick={() => openDialogCommission(row)}>
                                            <MdMonetizationOn color={colors.black} />
                                        </IconButton>
                                    }
                                    {User?.space !== "gestor" &&
                                        <IconButton>
                                            <MdTableView onClick={() => openDialogTable(row)} color={colors.black} />
                                        </IconButton>
                                    }
                                    {User?.space === "gestor" && defaultUser?.permissions?.permissions.includes("updateSalesCounterTables") &&
                                        <IconButton>
                                            <MdTableView onClick={() => openDialogTable(row)} color={colors.black} />
                                        </IconButton>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    )) :
                        <TableUI
                            page={page - 1}
                            onPageChange={(event, page) => setPage(page + 1)}
                            total={total}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={(rows) => {
                                setRowsPerPage(Number(rows.target.value))
                            }}
                            HeaderComponent={
                                <CustomTableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell align="left" sx={{ textTransform: 'none' }}>Identificação</TableCell>
                                    <TableCell align="left" sx={{ textTransform: 'none' }}>Responsável</TableCell>
                                    <TableCell align="left" sx={{ textTransform: 'none' }}>Email</TableCell>
                                    <TableCell align="left" sx={{ textTransform: 'none' }}>Whatsapp</TableCell>
                                    {User?.space !== "sales_counter" && <TableCell align="left" sx={{ textTransform: 'none' }}>Gestores</TableCell>}
                                    <TableCell align="left" sx={{ textTransform: 'none' }}>Status</TableCell>
                                    <TableCell align="left" sx={{ textTransform: 'none' }}>Ação</TableCell>
                                </CustomTableRow>
                            }
                            RowComponent={
                                listBranch && listBranch.map((row, i) => (
                                    <CustomTableRow type={'body'} key={i}>
                                        <TableCellCusttom sx={{ textTransform: 'none !important' }} title={row.sales_counter.id} />
                                        <TableCellCusttom sx={{ textTransform: 'none !important' }} title={row.sales_counter.identification_name} />
                                        <TableCellCusttom sx={{ textTransform: 'none !important' }} title={row.sales_counter.responsible_name} />
                                        <TableCellCusttom sx={{ textTransform: 'none !important' }} title={row.sales_counter.email} />
                                        <TableCellCusttom sx={{ textTransform: 'none !important' }} title={row.sales_counter.cellphone} />
                                        {User?.space !== "sales_counter" && <TableCellCusttom sx={{ textTransform: 'none !important' }} title={'gestor'} >
                                            <TableListGestorsUI data={row?.sales_counter?.gestors} />
                                        </TableCellCusttom >}
                                        <TableCellCusttom sx={{ textTransform: 'none !important' }} title={row.sales_counter.status}>
                                            <SelectUI
                                                key={row.sales_counter.id}
                                                identifier={row.sales_counter.id}
                                                value={row.sales_counter.active}
                                                options={[
                                                    {
                                                        color: colors.success800,
                                                        label: 'ATIVO',
                                                        value: true,
                                                        icon: <BsCheck2Circle color={colors.success800} size={22} style={{
                                                            minWidth: 22
                                                        }} />
                                                    },
                                                    {
                                                        color: colors.error,
                                                        label: 'INATIVO',
                                                        value: false,
                                                        icon: <ImBlocked color={colors.error} size={20} style={{
                                                            minWidth: 20
                                                        }} />
                                                    },
                                                ]}
                                                onChange={(value: any) => {
                                                    console.log(value, row.sales_counter)
                                                    handleActive(value, row.sales_counter.id)
                                                }}
                                            />
                                        </TableCellCusttom >
                                        <TableCellCusttom sx={{ textTransform: 'none !important', maxWidth: 125 }} title={row.owner.id} align='justify'>
                                            <Grid container gap={1}>
                                                {User?.space !== "gestor" &&
                                                    <IconButton onClick={() => openDialogCommission(row)}>
                                                        <MdMonetizationOn color={colors.black} />
                                                    </IconButton>
                                                }
                                                {User?.space === "gestor" && defaultUser?.permissions?.permissions.includes("updateSalesCounterCommission") &&
                                                    <IconButton onClick={() => openDialogCommission(row)}>
                                                        <MdMonetizationOn color={colors.black} />
                                                    </IconButton>
                                                }
                                                {User?.space !== "gestor" &&
                                                    <IconButton>
                                                        <MdTableView onClick={() => openDialogTable(row)} color={colors.black} />
                                                    </IconButton>
                                                }

                                                {User?.space === "gestor" && defaultUser?.permissions?.permissions.includes("updateSalesCounterTables") &&
                                                    <IconButton>
                                                        <MdTableView onClick={() => openDialogTable(row)} color={colors.black} />
                                                    </IconButton>
                                                }

                                                {User?.space !== "gestor" &&
                                                    <IconButton>
                                                        <MdEdit onClick={() => {
                                                            navigate('/app/filiais/editar-filial', {
                                                                state: {
                                                                    ...row
                                                                }
                                                            })
                                                        }} color={colors.black} />
                                                    </IconButton>
                                                }

                                                {User?.space !== "gestor" && defaultUser?.permissions?.permissions.includes("createSalesCounter") &&
                                                    <IconButton>
                                                        <MdEdit onClick={() => {
                                                            navigate('/app/filiais/editar-filial', {
                                                                state: {
                                                                    ...row
                                                                }
                                                            })
                                                        }} color={colors.black} />
                                                    </IconButton>
                                                }
                                            </Grid>
                                        </TableCellCusttom>
                                    </CustomTableRow>
                                ))
                            }
                        />
                }

                {matchesMaxMobile &&
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={total}
                        translate='yes'
                        labelDisplayedRows={({ to, from, count }) => <span style={{ fontWeight: 'bold' }}>{from} - {to} de {count} itens</span>}
                        labelRowsPerPage={`páginas:`}
                        rowsPerPage={rowsPerPage ?? 10}
                        page={page - 1}
                        onPageChange={(event, page) => setPage(page + 1)}
                        onRowsPerPageChange={(rows) => {
                            setRowsPerPage(Number(rows.target.value))
                        }}
                        backIconButtonProps={
                            page === 1
                                ? {
                                    disabled: true
                                }
                                : undefined
                        }

                    />
                }
            </Grid>
            <CommissionOnline
                salesCounter={salesCounterSelected}
                // @ts-ignore
                tablesOnline={tablesOnline.filter((table) => table.table.split("on-")[1] < 15)}
                userID={userID}
                setTablesOnline={setTablesOnline}
                open={openDialogOnlineCommission}
                handleClose={() => {
                    setOpenDialogOnlineCommission(false);
                    setUserID("")
                    setSalesCounterSelected(null)
                    setSaleTablesSalesCounter(null)
                    setTablesOnline(defaultTablesOnline)
                }}
            />
            <Managetables
                salesCounter={salesCounterSelected}
                open={openDialogTables}
                getSalesTables={getSalesTables}
                saleTablesSalesCounter={(saleTablesSalesCounter ?? [])?.filter((table) => Number(table.sale_table.split("on-")[1]) < 15)}
                allowedTables={allowedTables}
                handleClose={() => {
                    setOpenDialogTables(false);
                    setUserID("")
                    setSalesCounterSelected(null)
                    setSaleTablesSalesCounter(null)
                }}
            />
        </Grid>
    );
}