import { Grid, TextField, Typography } from "@mui/material"
import LoadingButton from '@mui/lab/LoadingButton';
import { colors } from "theme";
import Icons from "utils/icons";
import { moneyMask } from "@utils/moneymask";
import useProposal from "context/proposal";
import useMediasQuerys from "hooks/mediasQuery";


const Simulator = ({
    onChangeText,
    calcPreApproved,
    loadingPreApproved,
    limitError
}: {
    onChangeText: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    calcPreApproved: () => void;
    loadingPreApproved: boolean;
    limitError: string;
}) => {

    const { matchesMaxMobile } = useMediasQuerys()

    const { cardLimit, parcels } = useProposal();

    return (
        <Grid
            container
            flexDirection={"column"}
            borderRadius={"4px"}
            alignItems={"center"}
            gap={3}
            p={3}
            border={'1px solid ' + colors.grey300}
            bgcolor={colors.Background}
            xs={12}>
            <Typography variant="h6" fontWeight={"bold"} textTransform={"uppercase"} textAlign={matchesMaxMobile ? 'center' : 'start'}>Simule e inicie o empréstimo</Typography>
            <Typography variant="subtitle2" textTransform={"uppercase"} color={colors.grey600} fontWeight="400" textAlign="center">
                Simule e inicie o empréstimo DE R$ 300,00 a R$ 5.000,00 <br />
                DINHEIRO NA CONTA (EM ATÉ 01 HORa útil)
            </Typography>
            <Typography variant="h6" fontWeight={"400"} textAlign="center">
                Verifique corretamente o <strong>SALDO DISPONÍVEL</strong> para compras no cartão de crédito do cliente e digite abaixo
            </Typography>
            <Grid
                width={"100%"}
                maxWidth={580}
                container
                flexDirection={matchesMaxMobile ? 'column' : 'row'}
                gap={2}
            >
                <TextField
                    sx={{ width: matchesMaxMobile ? '100%' : 'calc(100% - 110px)', }}
                    variant="outlined"
                    inputProps={{
                        sx: {
                            color: (cardLimit && cardLimit !== 'R$ 0,0') ? colors.black : colors.grey500
                        }
                    }}
                    label="Digite o saldo correto aqui"
                    value={moneyMask(cardLimit)}
                    onChange={onChangeText}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            calcPreApproved()
                        }
                    }}
                    error={!!limitError}
                    helperText={limitError}
                />
                <LoadingButton
                    disabled={cardLimit === '0'}
                    sx={{ maxHeight: 54 }} loading={loadingPreApproved} onClick={calcPreApproved} variant="contained" disableElevation>
                    Simular
                </LoadingButton>
            </Grid>
            <Grid
                width={"100%"}
                maxWidth={580}
                container
                flexDirection={"row"}
                flexWrap={"nowrap"}
                justifyContent={"center"}
                gap={2}
            >
                <img src={Icons.IconAlert} alt="alert" />
                <Typography variant="subtitle2" fontWeight={"400"} textAlign="center" color={colors.grey600}>
                    Para evitar o bloqueio do cartão de crédito em nossa operação, verifique
                    o valor exato do <strong>saldo disponível para compras </strong> antes de digitar acima.
                </Typography>
            </Grid>
        </Grid>
    )
}

export default Simulator;