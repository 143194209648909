import { Button, CircularProgress, Grid, IconButton, TableCell, Typography } from '@mui/material';
import Breadcrumb from 'components/breadcrumbs';
import Icons from 'utils/icons';
import { colors } from 'theme';
import TableUI, { CustomTableRow, TableCellCusttom } from 'components/@ui/table';
import { AiFillEye } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import RemoteServices from 'service';
import { IndicatorsProps } from 'types/indicators';
import { useNavigate } from 'react-router';
import { UserService } from 'service/storage';
import Toast from 'components/@ui/toast';
import useMediasQuerys from 'hooks/mediasQuery';
import { TablePagination } from '@material-ui/core';

import ExcelIcon from 'assets/png/EXCEL.png';
import Axios from 'axios';
import URLSBases from 'utils/urlBases';
import storageLocales from 'utils/storageLocales';
import ModalUI from 'components/@ui/modal';
import ButtonUI from 'components/@ui/button';
import useGeneral from 'context/general';
import useAuth from 'context/auth';


export default function Indicators() {
    const {
        User
    } = useAuth()
    const { matchesMaxMobile } = useMediasQuerys()
    const { setChatOpen } = useGeneral()
    const navigate = useNavigate()

    const [indicated, setIndicated] = useState<IndicatorsProps[] | null>(null)

    const [rowsPerPage, setRowsPerPage] = useState<number>(10)

    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)

    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    const [canIndicate, setCanIndicate] = useState(false)
    const [openAlertCannotIndicate, setOpenAlertCannotIndicate] = useState(false)

    useEffect(() => {
        ListIndicators()
    }, [page, rowsPerPage])

    const ListIndicators = () => {
        RemoteServices.indicators.ListIndicators({
            limit: rowsPerPage,
            page
        })
            .then(({ response }) => {
                if (response?.result) {
                    setIndicated(response.result)
                    setTotal(response?.total)
                    setCanIndicate(response.can_indicate)
                }
            })
    }

    const download = async () => {
        setLoadingDownload(true)
        Axios({
            url: URLSBases.apisTc + "/online/indicator",
            params: {
                page: 1,
                limit: total,
                export: true,
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem(storageLocales.TCtoken)}`
            },
            responseType: 'blob'
        }).then((res) => {
            const fileUrl = URL.createObjectURL(res.data);
            const tempLink = document.createElement('a');
            tempLink.href = fileUrl;
            tempLink.setAttribute('download', 'correspondentes.xlsx');
            tempLink.click();
            document.body.removeChild(tempLink);
            URL.revokeObjectURL(fileUrl);
        }).finally(() => setLoadingDownload(false))
    }

    const sendIndicationLink = () => {
        const user = UserService.getUser()

        let licensedId = User?.space === "pdv" ? user?.licensedId : user?.owner?.affiliate_id
        let owner_user_id = User?.space === "pdv" ? UserService.getUserTc()?.sub : user?.owner?.user_id
        
        if (!licensedId || !owner_user_id) {
            Toast({
                type: 'info'
            }, "É necessário fazer login novamente para continuar")
            // signOut()
        } else {
            navigator.clipboard.writeText(`https://ayude.com.br/parceiros?licenciado=${licensedId}&correspondente=${owner_user_id}`)
            Toast({
                type: 'success'
            }, "Link copiado")
            // return window.open(`https://api.whatsapp.com/send?text=https://pdv.ayude.com.br/cadastro/${user.licensedId}/indicacao/${UserService.getUserId()}`)
        }
    }

    return (
        <Grid p={2}>
            <ModalUI open={openAlertCannotIndicate} handleClose={() => setOpenAlertCannotIndicate(false)}>
                <Grid p={4} mt={2} container gap={5} justifyContent={'center'}>
                    <Typography fontWeight={'bold'} textAlign={'center'}>Solicite a ativação da função INDICAR CORRESPONDENTE através do atendimento</Typography>
                    <Grid container justifyContent={'center'} gap={3}>
                        <ButtonUI
                            type="submit"

                            color="primary"
                            variant="contained"
                            onClick={() => {
                                setChatOpen(true)
                                setOpenAlertCannotIndicate(false)
                            }}
                        >
                            Atendimento
                        </ButtonUI>
                    </Grid>
                </Grid>
            </ModalUI>
            <Breadcrumb />
            <Grid p={2} mt={2}>
                <Grid
                    container
                    gap={2}
                    py={2}
                >
                    <img
                        src={Icons.IconCORRESPONDENTES}
                        alt="icon"
                        width={45}
                        style={{ filter: 'brightness(0)' }}
                    />
                    <Typography
                        textTransform={"uppercase"}
                        color={colors.black}
                        variant="h5"
                        fontWeight={"bold"}>CORRESPONDENTES</Typography>
                </Grid>
                <Grid
                    justifyContent={"flex-end"}
                    py={2}
                    container
                    gap={2}>
                    <Grid item minWidth={loadingDownload ? 224 : 200}>
                        <Button
                            startIcon={<img src={ExcelIcon} />}
                            sx={{
                                bgcolor: "#158249",
                                color: "white",
                                p: 1.8,
                                ":hover": {
                                    bgcolor: 'rgba(21, 130, 73, 0.9)',
                                },
                                ":disabled": {
                                    color: 'white'
                                }
                            }}
                            disabled={loadingDownload || total === 0}
                            onClick={download}
                            endIcon={loadingDownload && <CircularProgress size={24} style={{ color: 'white' }} />}
                        >Exportar para Excel</Button>
                    </Grid>
                    <Button
                        disableElevation
                        sx={{ p: 1.8, px: 4 }}
                        onClick={() => canIndicate ? sendIndicationLink() : setOpenAlertCannotIndicate(true)}
                        variant="contained">
                        Link para indicar correspondente
                    </Button>
                </Grid>
                {
                    matchesMaxMobile ?

                        indicated && indicated.map((row) => (
                            <Grid
                                p={1}
                                width="100%"
                                border={`1px solid ${colors.grey400}`}
                                my={2}
                                borderRadius={2}
                                bgcolor="white">
                                <Grid py={1} container borderBottom={`1px solid ${colors.grey400}`}>
                                    <Grid item fontWeight="bold">
                                        {row.trade_name}
                                    </Grid>
                                </Grid>
                                <Grid pt={1} container fontSize={12} color={colors.grey600} direction="column">
                                    <Grid item container flexDirection={'row'} gap={1} alignItems={"center"}>
                                        <Typography fontWeight="bold">ID: </Typography>{row.id}
                                    </Grid>
                                    <Grid item container flexDirection={'row'} gap={1} alignItems={"center"}>
                                        <Typography fontWeight="bold">Responsável: </Typography>{row.trade_name}
                                    </Grid>
                                    <Grid item container flexDirection={'row'} gap={1} alignItems={"center"}>
                                        <Typography fontWeight="bold">WhatsApp: </Typography>{row.cellphone}
                                    </Grid>
                                    <Grid item container flexDirection={'row'} gap={1} alignItems={"center"}>
                                        <Typography fontWeight="bold">Cidade - UF: </Typography>{row.address}
                                    </Grid>
                                </Grid>
                                <Grid pt={1} container borderTop={`1px solid ${colors.grey400}`} justifyContent="center">
                                    <Button
                                        sx={{
                                            bgcolor: colors.third + 50,
                                            color: colors.third,
                                            borderRadius: 12,
                                            p: .5
                                        }}
                                        onClick={() => navigate(`/app/correspondentes/${row.trade_name?.replaceAll(' ', '-').replaceAll('/', '_')}`, { state: { id: row.id } })}>
                                        <AiFillEye color={colors.third} size={22} style={{ marginRight: 4 }} />
                                        <Typography fontSize={12} fontWeight="bold">Ver propostas</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        ))
                        :
                        <TableUI
                            page={page - 1}
                            onPageChange={(event, page) => setPage(page + 1)}
                            total={total}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={(rows) => {
                                setRowsPerPage(Number(rows.target.value))
                            }}
                            HeaderComponent={
                                <CustomTableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell align="left">Nome</TableCell>
                                    <TableCell align="left">Responsável</TableCell>
                                    <TableCell align="left">Whatsapp</TableCell>
                                    <TableCell align="left">Cidade - UF</TableCell>
                                    <TableCell align="center">Propostas</TableCell>
                                </CustomTableRow>
                            }
                            RowComponent={
                                indicated && indicated.map((row) => (
                                    <CustomTableRow type="body">
                                        <TableCellCusttom title={row.id} />
                                        <TableCellCusttom title={row.trade_name} />
                                        <TableCellCusttom title={row.responsible_name} />
                                        <TableCellCusttom title={row.cellphone} />
                                        <TableCellCusttom title={row.address} />
                                        <TableCellCusttom title={row.id} align='center'>
                                            <IconButton onClick={() => {
                                                navigate(`/app/correspondentes/${row.trade_name?.replaceAll(' ', '-').replaceAll('/', '_')}`, { state: { id: row.id } })
                                            }}>
                                                <AiFillEye color={colors.black} />
                                            </IconButton>
                                        </TableCellCusttom>
                                    </CustomTableRow>
                                ))
                            }
                        />
                }
                {matchesMaxMobile &&
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={total}
                        translate='yes'
                        labelDisplayedRows={({ to, from, count }) => <span style={{ fontWeight: 'bold' }}>{from} - {to} de {count} itens</span>}
                        labelRowsPerPage={`páginas:`}
                        rowsPerPage={rowsPerPage ?? 10}
                        page={page - 1}
                        onPageChange={(event, page) => setPage(page + 1)}
                        onRowsPerPageChange={(rows) => {
                            setRowsPerPage(Number(rows.target.value))
                        }}
                    />
                }
            </Grid>
        </Grid>
    );
}